import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Grid,
  Typography,
  Container,
  Box,
  Button,
  Link,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import styled from 'styled-components'
import { HashLink } from 'react-router-hash-link'
import InfoIcon from '@material-ui/icons/Info'

import theme from 'theme'
import { SectionLight, SectionDark } from 'layouts/PageSections'
import { withStyles } from '@material-ui/core/styles'
import PickupButton from 'components/PickupButton'
import appImage from 'images/plynth_matchbook.png'
import cardImage from 'images/postcard_back.png'
import ActionButton from 'components/ActionButton'
import Emoji from 'components/Emoji'
import WebsiteNavBar from 'components/WebsiteNavBar'
import ScrollToTopOnMount from 'components/ScrollToTopOnMount'

const StyledBox = styled(Box)`
  background-color: ${theme.palette.background.default};
  background-image: linear-gradient(
    0deg,
    ${theme.palette.primary.main}70,
    ${theme.palette.primary.main}00
  );
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
`

const AppImage = styled.img`
  height: 100%;
  max-height: 600px;
  width: 100%;
  object-fit: contain;
  object-position: 50% 50%;
  display: block;
`

const CardImage = styled(AppImage)`
  width: 75%;
  height: 80%;
  padding: 20% 0;
  transform: rotate(-20deg);
`

const StyledTooltip = withStyles(theme => ({
  tooltipPlacementTop: {
    margin: '0',
  },
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    maxWidth: 220,
  },
}))(Tooltip)

const StyledInfo = styled(InfoIcon)`
  opacity: 0.4;
  &:hover {
    opacity: 0.6;
  }
`

const SmoothHashLink = React.forwardRef((props, ref) => (
  <HashLink smooth innerRef={ref} {...props} />
))

const Home = () => {
  let vh = window.innerHeight * 0.01

  document.documentElement.style.setProperty('--vh', `${vh}px`)

  return (
    <>
      <ScrollToTopOnMount />
      <Container disableGutters maxWidth={false}>
        <StyledBox>
          <Grid
            container
            direction="column"
            align="center"
            justify="space-between"
            wrap="nowrap"
            style={{ height: '100%' }}
          >
            <Grid item>
              <WebsiteNavBar
                position="static"
                opacity="0.4"
                left={
                  <Grid item xs={1}>
                    <Grid container justify="flex-start">
                      <Grid item>
                        <StyledTooltip
                          title={
                            <>
                              <Typography color="inherit">
                                Welcome to Plynth. It's like a QR code, without
                                the QR code.
                              </Typography>
                              <br />
                              <Typography color="inherit">
                                Give it a try:
                              </Typography>
                              <Typography color="inherit">
                                1. Upload a photo of a piece of art
                              </Typography>
                              <Typography color="inherit">
                                2. Access the content it's linked to!
                              </Typography>
                            </>
                          }
                          enterTouchDelay={0}
                          leaveTouchDelay={10000}
                          interactive={true}
                          arrow={true}
                          placement="bottom-start"
                        >
                          <IconButton aria-label="info">
                            <StyledInfo />
                          </IconButton>
                        </StyledTooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                }
                right={
                  <Grid item xs={1}>
                    <Grid container justify="flex-end">
                      <Grid item>
                        <Button
                          component={RouterLink}
                          to="/admin/login"
                          style={{ textTransform: 'none' }}
                        >
                          <p style={{ opacity: 0.7 }}>Sign In</p>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                }
              />
            </Grid>
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <PickupButton />
                </Grid>
                <Box height="1rem"></Box>
                <Grid item>
                  <Typography variant="h6">
                    Take a photo to access your content
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item></Grid>
          </Grid>
        </StyledBox>
      </Container>
    </>
  )
}

export default Home
